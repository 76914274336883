<template>
  <el-row>
    <el-col :span="24">
      <div class="title big">
        LINKS
      </div>
      <div class="title">
        <a href="#">Additional link</a>
        <a href="#">Additional link</a>
        <a href="#">Additional link</a>
        <a href="#">Additional link</a>
        <a href="#">Additional link</a>
        <a href="#">Additional link</a>
      </div>
    </el-col>
  </el-row>
</template>
<script>
export default {
  name: 'Contact'
}
</script>
<style scoped>
.title{
  display: block;
  width: 100%;
  text-align: left !important;
  color: white;
}
.big{
  font-weight: 700;
  margin-bottom: 30px;
}
.phone{
  color: rgb(237, 29, 36);
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 20px;
}
a{
  display: block;
  text-decoration: none;
  color: white;
  font-size: 13px;
  margin-bottom: 10px;
}
a:hover{
  color: rgb(237, 29, 36);
  
}
.links{
  display: flex;
  margin-top: 35px;
}
.links a{
  margin-right: 10px;
}
</style>