<template>
  <el-row>
    <el-col :span="24">
      <div class="title big">
        CONTACT INFOMATION
      </div>
      <div class="title" style="margin-bottom: 10px;">
        Call Us 24/7 Free
      </div>
      <div class="title phone">
        1 001 234 5678
      </div>
      <div class="title">
        <a href="#">Support@domain.com</a>
      </div>
      <div class="title">
        <a href="#">60, 29th Street, San Francisco, CA 94110, United States</a>
      </div>
      <div class="links">
        <a href="#"><img src="../../assets/app-store.jpg" alt=""></a>
        <a href="#"><img src="../../assets/google-play.jpg" alt=""></a>
      </div>
    </el-col>
  </el-row>
</template>
<script>
export default {
  name: 'Contact'
}
</script>
<style scoped>
.title{
  display: block;
  width: 100%;
  text-align: left !important;
  color: white;
}
.big{
  font-weight: 700;
  margin-bottom: 30px;
}
.phone{
  color: rgb(237, 29, 36);
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 20px;
}
a{
  display: block;
  text-decoration: none;
  color: white;
  font-size: 13px;
  margin-bottom: 10px;
}
a:hover{
  color: rgb(237, 29, 36);
  
}
.links{
  display: flex;
  margin-top: 35px;
}
.links a{
  margin-right: 10px;
}
</style>