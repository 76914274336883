import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    detail:{},
    products: 0,
    productList: []
  },
  getters: {
    detail: state => state.detail,
    products: state => state.products,
    productList: state => state.productList,
  },
  mutations: {
    SetDetail(state, newData){
      state.detail = newData;
    },
    SetProducts(state, newData){
      state.products = newData;
    },
    SetProductList(state, newData){
      state.productList.push(newData);
    }
  },
  actions: {
  },
  modules: {
  }
})
