<template>
  <el-row class="main">
    <el-col :span="20" :offset="2" class="grid-content">
      <el-row style="width: 100%; padding: 70px 0px">
        <el-col v-for="(item, key) in brandList" :key="key" :span="4" class="items">
          <el-image
            style="width: 100px; height: 100px"
            :src="getImage(item.key)"
            @click="navigate(item.key)"
            :fit="'contain'">
          </el-image>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>
<script>
export default {
  name: 'Brands',
  data(){return{
    brandList: [
      {name: 'Nokia', key: 'nokia'},
      {name: 'Apple', key: 'apple'},
      {name: 'Samsung', key: 'samsung'},
      {name: 'Xiaomi', key: 'xiaomi'},
      {name: 'ZTE', key: 'zte'},
      {name: 'Realmi', key: 'realmi'},
    ]
  }},
  methods:{
    getImage(value){
      return require(`../../assets/logo/${value}.svg`)
    },
    navigate(value){
      this.$router.push({ path: `/category/${value}`, params: { name: 'private' } })
    }
  }
}
</script>
<style scoped>
.grid-content{
  display: flex;
  justify-content: space-between;
}
.items{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  
}
.el-image{
  transition: width 0.5s ease !important;
}
.items:hover .el-image{
  width: 70px !important;
  height: 70px !important;
}
</style>