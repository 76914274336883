<template>
  <el-row class="main">
    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="product-main">
      <el-image
      style="width: 100%; height: 500px"
      :src="require('../../assets/images/1.jpg')"
      :fit="'cover'"></el-image>
    </el-col>
    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="product-all">
      <el-row class="main">
        <el-col :span="12" class="product-all-item">
          <el-image
            style="width: 100%; height: 250px"
            :src="require('../../assets/images/5c2fe8c2745ae64ba93c5a656fea89184551adc1.jpeg')"
            :fit="'cover'"></el-image>
        </el-col>
        <el-col :span="12" class="product-all-item">
          <el-image
            style="width: 100%; height: 250px"
            :src="require('../../assets/images/xiaomi_redmi_9c_2_32gb_orange.jpg')"
            :fit="'cover'"></el-image>
        </el-col>
        <el-col :span="12" class="product-all-item">
          <el-image
            style="width: 100%; height: 250px"
            :src="require('../../assets/images/xlg_Galaxy-A-Unpacked_main2_1616146398.jpg')"
            :fit="'cover'"></el-image>
        </el-col>
        <el-col :span="12" class="product-all-item">
          <el-image
            style="width: 100%; height: 250px"
            :src="require('../../assets/images/maxresdefault.jpg')"
            :fit="'cover'"></el-image>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>
<script>
export default {
  name: 'Gallery'
}
</script>
<style scoped>
.product-main{
  background: red;
}
.product-main{
  height: 500px;
}
.product-all-item{
  height: 250px;
}
>>>.el-image img{
  transition: transform 0.5s ease !important;
}
>>>.el-image:hover img{
  transform: scale(1.5);
}
</style>