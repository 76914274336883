<template>
  <div>  
    <el-row class="footer">
      <el-col :span="20" :offset="2">
        <el-row>
          <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
            <Contact/>
          </el-col>
          <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
            <Links/>
          </el-col>
          <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
            <Links/>
          </el-col>
          <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
            <Map/>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row class="years">
      <el-col :span="20" :offset="2">
        <div class="year_info">
          <div>
            Copyright &copy; {{new Date().getFullYear()}} <a href="https://fazylov.uz/" >fazylov.uz</a>
          </div>
          <div class="payments">
            <a href="#" class="payment"><img src="../assets/payments/visa.svg" alt=""></a>
            <a href="#" class="payment"><img src="../assets/payments/master.svg" alt=""></a>
            <a href="#" class="payment"><img src="../assets/payments/paypal.svg" alt=""></a>
            <a href="#" class="payment"><img src="../assets/payments/discover.svg" alt=""></a>
            <a href="#" class="payment"><img src="../assets/payments/amex.svg" alt=""></a>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import Contact from '@/components/FooterComponent/Contact.vue'
import Map from '@/components/FooterComponent/Map.vue'
// import Map from '@/components/FooterComponent/Map.vue'
import Links from './FooterComponent/Links.vue'
export default {
  name: 'Footer',
  components:{
    Contact,
    Map,
    Links
  }
}
</script>
<style scoped>
.footer{
  background-color: #1b1b1b;
  padding-top: 30px;
  padding-bottom: 80px;
}
.years{
  padding: 25px 0;
  background: #323232;
  font-weight: 400;
  color: white
}
.year_info{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.payments{
  display: flex;
  justify-content: right;
}
.payment{
  margin-left: 15px;
}
.payment img{
  width: 30px;
}
a{
  color: white; text-decoration: none
}
a:hover{
  color: #ed1d24;
}
</style>