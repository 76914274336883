<template>
  <div>
    <img src="../../assets/world-news.png" alt="">
  </div>
</template>
<script>
export default {
  name: 'Map'
}
</script>
<style scoped>
img{
  width: 70%;
}
</style>