<template>
  <div class="home">
    <el-carousel height="800px">
    <el-carousel-item v-for="(item, key) in imageList" :key="key">
      <el-image
        style="width: 100%; height: 100%"
        :src="require(`../assets/banner/${item.img}`)"
        :fit="'cover'"></el-image>
    </el-carousel-item>
  </el-carousel>
  <Brands/>
  <Gallery/>
  <Advertising/>
  
  </div>
</template>

<script>
// @ is an alias to /src
import Brands from '@/components/HomeComponent/Brands.vue'
import Gallery from '@/components/HomeComponent/Gallery.vue'
import Advertising from '@/components/HomeComponent/Advertising.vue'
export default {
  name: 'Home',
  components: {
    Brands,
    Gallery,
    Advertising
  },
  data(){
    return{
      imageList: [
        {img: 'Apple-2018-iPhone-XR-Newest-Smartphone-Wallpaper-Background-2560x1600.jpg'},
        {img: 'zte-axon-7.jpg'},
        {img: '1-5.jpg'},
      ]
    }
  }
}
</script>
<style scoped>
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 800px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #1b1b1b;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #1b1b1b;
}
>>>.el-carousel__button{
  width: 15px !important;
  height: 15px !important;
  border-radius: 15px !important;
  background: white !important;
  border: solid 1px white !important;
}
>>>.el-carousel__indicator.el-carousel__indicator--horizontal.is-active .el-carousel__button{
  background: #ed1d24 !important;
  border: solid 1px white;
}
</style>


