<template>
  <el-row class="main">
    <el-col :span="20" :offset="2" class="grid-content">
      <img src="@/assets/Mall-tv-logo.png" alt=""  @click="$router.push('/')">
      <div class="naw">
        
        <div class="nav-item"><a href="#">MAIN</a></div>
        <div class="nav-item"><a href="#">PRODUCTS</a></div>
        <div class="nav-item"><a href="#">CONTACTS</a></div>
        <div class="nav-item"><a href="#">INFO</a></div>
      </div>
      <div class="btns">
        <i class="el-icon-search"></i>
        <i class="el-icon-user"></i>
        <i class="el-icon-star-off"></i>
        <el-badge :value="products" class="item">
        <i class="el-icon-goods" @click="$router.push('/basket')"></i>
        </el-badge>
      </div>
    </el-col>
  </el-row>
</template>
<script>
import {mapGetters/*, mapMutations*/} from "vuex";
export default {
  name: 'Header',
  computed:{
    ...mapGetters({
      products: "products",
    }),
  },
}
</script>
<style scoped>
.main{
  background: #1b1b1b;
  padding-top: 27px;
  padding-bottom: 25px;
}
.grid-content{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
img{
  height: 24px;
}
.btns{
  font-size: 24px;
  color: white;
  display: flex;
  align-items: center;
}
.btns i{
  margin: 5px 10px;
  cursor: pointer;
}
.btns i:hover{
  color: red;
}

.naw{
  display: flex;
}
.nav-item{
  justify-content: left;
  margin-left: 20px;
  font-weight: 600;
  color: white;
  font-size: 14px;
}
a{
  text-decoration: none;
  color:white
}

>>>.el-badge__content.is-fixed{
  transform: translate(0px, 0px) !important;
  right: 0px;
}
>>>.el-badge__content{
  background-color: #ed1d24 !important;
  border-color:#ed1d24 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
