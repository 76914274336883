<template>
  <el-row class="ads">
    <el-col :span="20" :offset="2">
      <div class="content">
        <div class="banner">
          <div class="info">
            <h3 class="title-banner">Latest &amp; Special Brands</h3>
            <a href="" class="button">SHOP NOW</a>
          </div>
        </div>
      <div class="item-group">
        <span class="item">
          <img src="../../assets/icon/like.png" alt="">
          <div class="item-description">
            <h4>100% Satisfaction</h4>
            <span>If you are unable</span>
          </div>
        </span>
        <el-divider direction="vertical"></el-divider>
        <span class="item">
          <img src="../../assets/icon/money.png" alt="">
          <div class="item-description">
            <h4>Save 20% when you</h4>
            <span>Use credit card</span>
          </div>
        </span>
        <el-divider direction="vertical"></el-divider>
        <span class="item">
          <img src="../../assets/icon/plane.png" alt="">
          <div class="item-description">
            <h4>Fast Free Shipment</h4>
            <span>Load any computer’s</span>
          </div>
        </span>
        <el-divider direction="vertical"></el-divider>
        <span class="item">
          <img src="../../assets/icon/piggy-bank.png" alt="">
          <div class="item-description">
            <h4>60-Day Money back</h4>
            <span>If you are unable</span>
          </div>
        </span>
      </div>
      </div>
    </el-col>
  </el-row>
</template>
<script>
export default {
  name: 'Advertising'
}
</script>
<style scoped>
.ads{
  background-color: #f5f5f5;
}
.content{
  background: white;
  padding: 105px 150px;
}
.banner{
  position: relative;
  margin-bottom: 110px;
  background-image: url(../../assets/banner3-1.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  visibility: visible;
    animation-duration: 1.5s;
    animation-delay: 0.5s;
    animation-name: fadeInUp;
}
.info{
  text-align: center;
  z-index: 1;
  padding: 75px 0;
}
.title-banner{
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 5px;
  margin-bottom: 20px;
  padding: 0 30px;
}
.button{
  border: solid 1px white;
  padding: 10px 30px;
  color:white;
  text-decoration: none;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.button:hover{
  background: rgb(237, 29, 36);
  border-color: rgb(237, 29, 36);
}
.item-group{
  display: flex;
}
.item{
  display: flex;
  width: 25%;
  align-items: center;
  justify-content: center;
}
.item img{
  width: 40px;
  margin-right: 15px;
}
.el-divider{
  height: 3em;
}
h4{
  margin: 0px;
}
.item-description{
  text-align: left;
}
.item-description span{
  color: #666
}
</style>